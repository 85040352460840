import '../css/Cart.css'
import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import CircularIndeterminate from '../components/CircularLoad';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SimpleAlert from '../components/SimpleAlert';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

export default function Cart({message, type, alertDisplay}){
    const [products, setProducts] = useState(null);

    function increment(productId) {
        updateCounter(productId, 1);
    }
    
    function decrement(productId) {
        updateCounter(productId, -1);
    }
    
    const updateCounter = async (productId, change) => {
        const updatedProducts = products.map(product => {
            if (product.product._id === productId) {
                const newNbOrdered = product.quantity + change;
                if (newNbOrdered >= 1 && newNbOrdered <= 10) {
                    return { ...product, quantity: newNbOrdered };
                }
            }
            return product;
        });
        setProducts(updatedProducts);
        try {
            await fetch("/api/user/cart", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    productId: productId, 
                    nbOrdered: products.find(product => product.product._id === productId).quantity
                })
            });
        } catch (error) {
            console.error("Error:", error);
        }
    }
    
    function confirmRemove(productId) {
        const confirmation = window.confirm('Are you sure you want to remove this product from your cart?');
        if (confirmation) {
            removeProduct(productId);
        } else {
            // User clicked Cancel, do nothing
        }
    }
    
    const removeProduct = async (productId) => {
        const updatedProducts = products.filter(product => product.product._id !== productId);
        setProducts(updatedProducts);
        try {
            await fetch('/api/user/removeFromCart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productID: productId })
            });
        } catch (error) {
            console.error('Error removing product from cart:', error);
        }
    }

    useEffect(() => {
        fetchCartProducts();
    }, []);
    
    const fetchCartProducts = async () => {
        try {
          const response = await fetch('/api/user/cart');
          if (response.ok) {
            const data = await response.json();
            setProducts(data.cart);
            console.log(data.cart)
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    if (products === null) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    if (products.length === 0) {
        return (
            <h1 style={{textAlign: 'center'}}>No products currently</h1>
        )
    }

    return (
        <div id='content'>
            <SimpleAlert key="message-alert" message={message} type={type} display={alertDisplay}/>
            <div id="cart-table" style={{ overflowX: 'auto' }}>
                <h3 style={{ textAlign: 'center', marginBottom: '15px' }}>Cart</h3>
                <Table bordered>
                    <tbody>
                        {products.map((product) => (
                            <tr style={{borderTop: 'none'}} id={`product-${product.product._id}`} key={`product-${product.product._id}`}>
                                <td style={{borderRight: 'none'}}>
                                    <p><img className='productImg' src={product.product.image.url} style={{marginRight: '10px'}}/></p>
                                </td>
                                <td style={{ borderRight: 'none', borderLeft: 'none' }}>
                                    {product.product.name}
                                </td>
                                <td className='productName' style={{ borderRight: 'none', borderLeft: 'none' }}>
                                    <Link to={`/product/${product.product._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                        {product.product.english_name}
                                    </Link>
                                    <p style={{fontWeight: 'bold'}}>$ {product.product.price}</p>
                                </td>
                                <td style={{ display: 'flex', alignItems: 'center', borderLeft: 'none' }}>
                                    <IconButton style={{color: 'black'}} id={`iconbtn-${product.product._id}`} onClick={() => product.quantity === 1 ? confirmRemove(product.product._id) : decrement(product.product._id)}>
                                        {product.quantity === 1 ? <DeleteIcon className='iconBtn' style={{ fontSize: '17px'}}/> : <RemoveIcon className='iconBtn' style={{ fontSize: '25px'}}/>}
                                    </IconButton>
                                    <input style={{ textAlign: 'center'}} className='countInput' value={product.quantity} id={`counter-${product.product._id}`} name={product.product._id} />
                                    <IconButton style={{color: 'black'}} onClick={() => increment(product.product._id)}>
                                        <AddIcon className='iconBtn' style={{ fontSize: '17px'}}/>
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Link id='checkoutBtnLink' to="/user/checkout" style={{ textDecoration: 'none', display: products !== null && products.length > 0 ? 'block' : 'none', marginTop: '10px', float: 'right' }}>
                    <Button style={{backgroundColor: 'black', textTransform: 'none'}} id='checkoutBtn' variant="contained"><ShoppingCartCheckoutIcon/> Checkout</Button>
                </Link>
            </div>
        </div>
    );
}