import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import BusinessAccountInfo from './BusinessAccountInfo';
import SimpleAlert from '../components/SimpleAlert';
import AddStore from './AddStore';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit">
        Delivery
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Account info', 'Add your store'];

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [errorAlert, setErrorAlert] = React.useState('none');

  const handleResponse = (response) => {
    if (response === 200) {
        handleNext();
    } else {
        setErrorAlert('block')
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <BusinessAccountInfo passResponse={handleResponse}/>;
      case 1:
        return <AddStore/>;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
                Business Account
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <SimpleAlert key="alert" message='Error' type='error' display={errorAlert}/>
            <React.Fragment>
                {getStepContent(activeStep)}
            </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}