import ReactDOM from "react-dom/client";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
// PAGES
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Store from "./pages/Store";
import AddStore from "./pages/AddStore";
import Test from "./pages/Test";
import AddProduct from "./pages/AddProduct";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import BusinessSignUp from "./pages/BusinessSignUp";
import NormalSignUp from "./pages/NormalSignUp";
import Checkout from "./pages/Checkout";
import Stores from "./pages/Stores";
import Account from "./pages/Account";
import VerifyEmail from "./pages/VerifyEmail";
import EditStore from "./pages/EditStore";
import CreditCard from "./pages/CreditCard";
import OrderHistory from "./pages/OrderHistory";
import StoreTypeResults from "./pages/StoreTypeResults";
import Cart from "./pages/Cart";
import FavoriteStores from "./pages/FavoriteStores";
import AddAddress from "./pages/AddAddress";
import EditAddress from "./pages/EditAddress";
import Addresses from "./pages/Addresses";
import ShowOrder from "./pages/ShowOrder";
// COMPONENTS
import CircularIndeterminate from './components/CircularLoad';

export default function App() {
  const [authenticated, setAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
      fetchUserStatus();
  }, []);

  const fetchUserStatus = async () => {
    try {
      const response = await fetch('/api/user/status');
      if (response.ok) {
        const results = await response.json();
        setAuthenticated(results.authenticated)
        setUser(results.user)
      } else {
        console.error('Failed to fetch user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (authenticated === null && user === null) {
      return (
        <div id="loading-container">
            <CircularIndeterminate display={true}/>
        </div>
      )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          {/* USER */}
          <Route path="/user/checkout" element={authenticated ? (user.cart.length > 0 ? <Checkout /> : <Cart message="You cart is empty" type='info' alertDisplay='block'/>) : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/stores" element={<Stores />} />
          <Route path="/user/signup" element={<SignUp />} />
          <Route path="/user/login" element={<SignIn />} />
          <Route path="/user/account" element={authenticated ? <Account/> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/signup/personal" element={<NormalSignUp />} />
          <Route path="/user/card" element={authenticated ? <CreditCard/> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/signup/business" element={<BusinessSignUp />} />
          <Route path="/user/add-address" element={authenticated ? <AddAddress /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/addresses" element={authenticated ? <Addresses /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/address/:index" element={authenticated ? <EditAddress /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/cart" element={authenticated ? <Cart /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/order-history" element={authenticated ? <OrderHistory/> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/favorite-stores" element={authenticated ? <FavoriteStores /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>

          {/* STORE */}
          <Route path="/store/:id" element={<Store />} />
          <Route path="/stores/:type" element={<StoreTypeResults />} />
          <Route path="/store/:id/add-product" element={<AddProduct />} />
          <Route path="/store/:id/edit" element={<EditStore />} />

          {/* ORDER */}
          <Route path="/order/:orderNb" element={<ShowOrder />} />

          {/* OTHER */}
          <Route path="/test" element={<Test />} />
          <Route path="/addstore" element={<AddStore />} />
          <Route path="*" element={<Home message={'Page not found'} type={'info'} alertDisplay={'block'}/>} />
        </Route>
        
        <Route path="/verify-email/:email/:token" element={<VerifyEmail/>}/>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
