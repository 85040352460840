export const addToCart = async (storeID, productID, quantity = 1, callback) => {
    try {
        const response = await fetch('/api/user/addToCart', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ storeID, productID, quantity })
        });
        if (response.ok) {
            // callback(); // Execute the callback function after addToCart logic
            // sessionStorage.setItem('sessionMessage', 'Product added to cart');
            // window.location.reload();
        } else if (response.status === 401){
            console.error("Error: You must be logged in");
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};