import { useNavigate  } from 'react-router-dom';
import { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
         Delivery
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function AddProduct() {
    const [loader, setLoader] = useState(false);
    const [category, setCategory] = useState('');
    const [store, setStore] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        fetchStore();
    }, []);

    const fetchStore = async() => {
        try {
            const response = await fetch(`/api/store/${id}`);
            if (response.ok) {
              const data = await response.json();
              setStore(data.store)
            } else {
              console.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        name: { required: "First name cannot be blank" },
        category: { required: "Last name cannot be blank" },
        price: { required: "Street cannot be blank" },
        category: {required: "City cannot be blank"}
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleAddProduct = async (formData) => {
        setLoader(true);
        const { name, description, price } = formData;
        const productData = {
            product: {
                name,
                description,
                category,
                price,
                store: id
            }
        };
        const form = new FormData();
        form.append('image', formData.image[0]); // Append the selected image file
        Object.entries(productData.product).forEach(([key, value]) => {
            form.append(`product[${key}]`, value); // Append each form field
        });

        try {
            const response = await fetch("/api/product/add", {
                method: "POST",
                body: form
            });

            if (response.ok) {
                console.log('Product added successfully');
            } else {
                console.error("Failed to add product");
            }
        } catch (error) {
            console.error("Error:", error);
        }

        setLoader(false);
    };
    const handleError = (errors) => {};

    if(store === null) {
        return (
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container key="main-container" component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'black' }}>
                            <FastfoodIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h1" variant="h5">
                            Add product
                        </Typography>
                        <CircularIndeterminate key="loading-spinner" display={true}/>
                    </Box>
                    <Copyright key="copyright" sx={{ mt: 5 }} />
                </Container>
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                    <FastfoodIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Add product
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit(handleAddProduct)} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="name"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                autoFocus
                                {...register("name", { required: "First name cannot be blank" })}
                            />
                            <small className="text-danger">
                                {errors?.name && errors.name.message}
                            </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                {...register("description", { required: "Last name cannot be blank" })}
                            />
                            <small className="text-danger">
                                {errors?.description && errors.description.message}
                            </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Type"
                                    value={category}
                                    onChange={handleCategoryChange}
                                >
                                    {store.categories.map((category, index) => (
                                        <MenuItem key={index} value={category.name}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <small className="text-danger">
                                {errors?.category && errors.category.message}
                            </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="file"
                                id="image"
                                name="image"
                                inputProps={{ multiple: false }}
                                {...register("image")}
                            />
                            <small className="text-danger">
                                {errors?.image && errors.image.message}
                            </small>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="price"
                                required
                                fullWidth
                                id="price"
                                label="Price"
                                autoFocus
                                {...register("price", { required: "Price cannot be blank" })}
                            />
                            <small className="text-danger">
                                {errors?.price && errors.price.message}
                            </small>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: 'black' }}
                    >
                        Save
                    </Button>
                </Box>
                {loader && <div style={{ marginTop: '30px' }}>Loading...</div>}
            </Box>
        </Container>
    </ThemeProvider>
    );
}