import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate({display=false}) {
  return (
    <Box sx={{ display: display ? 'flex' : 'none' }}>
      <CircularProgress style={{color: 'black'}}/>
    </Box>
  );
}