import '../css/ShowOrder.css'
import * as React from 'react';
import { useState, useEffect } from "react";
import Link from '@mui/material/Link';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Avatar from '@mui/material/Avatar';
import CircularIndeterminate from '../components/CircularLoad';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

export default function ShowOrder() {
    const { orderNb } = useParams();
    const [order, setOrder] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        fetchOrder();
    }, []);

    const fetchOrder = async () => {
        try {
          const response = await fetch(`/api/order/${orderNb}`);
          if (response.ok) {
            const results = await response.json();
            setOrder(results.order)
          } else {
            console.error('Failed to fetch order');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    if (order === null) {
        return (
            <div id="table">
                <Table style={{ marginTop: '5%' }}>
                    <CircularIndeterminate display={true}/>
                </Table>
            </div>
        )
    }

    return (
        <div id="showordertable">
            {/* <Avatar key="avatar" sx={{ m: 1, bgcolor: "green", textAlign: 'center' }}>
                <ReceiptLongIcon />
            </Avatar> */}
            <h3 style={{textAlign: 'center'}}>Order #{order.number}</h3>
            <h4 id='orderstatus-h4' style={{textAlign: 'center'}}>Status: {order.status}</h4>
            <Table style={{ marginTop: '5%' }}>
                <tbody>
                    {order.products.map((product) => (
                        <tr id={`product-${product.product._id}`} key={`product-${product.product._id}`}>
                            <td style={{ borderBottom: 'none' }}>
                                <img className='productImg' src={product.product.image.url} style={{marginRight: '10px'}}/>
                            </td>
                            <td className='productName' style={{ borderRight: 'none', borderBottom: 'none' }}>
                                <Link to={`/product/${product.product._id}`} style={{ color: 'inherit', textDecoration: 'none', fontWeight: '100' }}>
                                    {product.product.name}
                                </Link>
                            </td>
                            <td className='productPrice' style={{textAlign: 'center', borderLeft: 'none', borderBottom: 'none'}}>${product.product.price}</td>
                            <td style={{borderBottom: 'none'}}>{product.nb_ordered}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}