import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
         Delivery
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function BusinessAccountInfo({passResponse}) {
    const [loader, setLoader] = React.useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        first_name: { required: "First name cannot be blank" },
        last_name: { required: "Last name cannot be blank" },
        username: {required: "Username cannot be blank"},
        email: {
            required: "Email cannot be blank",
            pattern: {
                value: /^([_\-\.0-9a-zA-Z]+)@([_\-\.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/,
                message: "Invalid email address"
            }
        },
        password: {
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters",
          },
        }
    };

    const handleRegister = async (formData) => {
        setLoader(true)
        const { first_name, last_name, title, username, email, password } = formData;
        const type = 'business';
        try {
            const response = await fetch("/api/user/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: { first_name, last_name, title, username, email, type },
                    password
                })
            });
            if (response.ok) {
                passResponse(200)
            } else {
                console.error("Failed");
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoader(false)
    };
    const handleError = (errors) => {};

    return (
        <ThemeProvider key="theme-provider" theme={defaultTheme}>
          <Container key="main-container" component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                  key="box"
                  sx={{
                      marginTop: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}
              >
                  <Avatar key="avatar" sx={{ m: 1, bgcolor: 'black' }}>
                      <LockOutlinedIcon />
                  </Avatar>
                  <Typography key="sign-up-title" component="h1" variant="h5">
                      Account info
                  </Typography>
                  <Box key="form-box" component="form" noValidate onSubmit={handleSubmit(handleRegister, handleError)} sx={{ mt: 3 }}>
                      <Grid key="grid-container" container spacing={2}>
                          <Grid key="first-name-field" item xs={12} sm={6}>
                              <TextField
                                  key="first-name-input"
                                  autoComplete="first-name"
                                  name="first_name"
                                  required
                                  fullWidth
                                  id="first_name"
                                  label="First Name"
                                  autoFocus
                                  {...register("first_name", registerOptions.first_name)}
                              />
                              <small className="text-danger">
                                  {errors?.first_name && errors.first_name.message}
                              </small>
                          </Grid>
                          <Grid key="last-name-field" item xs={12} sm={6}>
                              <TextField
                                  key="last-name-input"
                                  required
                                  fullWidth
                                  id="last_name"
                                  label="Last Name"
                                  name="last_name"
                                  autoComplete="last-name"
                                  {...register("last_name", registerOptions.last_name)}
                              />
                              <small className="text-danger">
                                  {errors?.last_name && errors.last_name.message}
                              </small>
                          </Grid>
                          <Grid key="username-field" item xs={12}>
                              <TextField
                                  key="username-input"
                                  required
                                  fullWidth
                                  id="username"
                                  label="Username"
                                  name="username"
                                  autoComplete="username"
                                  {...register("username", registerOptions.username)}
                              />
                              <small className="text-danger">
                                  {errors?.username && errors.username.message}
                              </small>
                          </Grid>
                          <Grid key="email-field" item xs={12}>
                              <TextField
                                  key="email-input"
                                  required
                                  fullWidth
                                  id="email"
                                  label="Email Address"
                                  name="email"
                                  autoComplete="email"
                                  {...register("email", registerOptions.email)}
                              />
                              <small className="text-danger">
                                  {errors?.email && errors.email.message}
                              </small>
                          </Grid>
                          <Grid key="password-field" item xs={12}>
                              <TextField
                                  key="password-input"
                                  required
                                  fullWidth
                                  name="password"
                                  label="Password"
                                  type="password"
                                  id="password"
                                  autoComplete="new-password"
                                  {...register("password", registerOptions.password)}
                              />
                              <small className="text-danger">
                                  {errors?.password && errors.password.message}
                              </small>
                          </Grid>
                      </Grid>
                      <Button
                          key="sign-up-button"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2, bgcolor: 'black' }}
                      >
                          Sign Up
                      </Button>
                      <Grid key="link-container" container justifyContent="flex-end">
                          <Grid key="login-link-item" item>
                              <Link key="login-link" href="/user/login" variant="body2">
                                  Already have an account? Sign in
                              </Link>
                          </Grid>
                      </Grid>
                  </Box>
                  <div style={{marginTop: '30px'}}>
                      <CircularIndeterminate key="loading-spinner" display={loader}/>
                  </div>
              </Box>
          </Container>
      </ThemeProvider>
      );
}