import '../css/OrderHistory.css'
import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import SessionAlert from '../components/SessionAlert';
import CircularIndeterminate from '../components/CircularLoad';

export default function OrderHistory(){
    const [orders, setOrders] = useState(null);

    useEffect(() => {
        fetchOrderHistory();
    }, []);
    
    const fetchOrderHistory = async () => {
        try {
          const response = await fetch('/api/user/order-history');
          if (response.ok) {
            const data = await response.json();
            setOrders(data);
            console.log(data)
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    if (orders === null) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    return (
        <div>
            <SessionAlert/>
            <div id="orderhistory-table" style={{ overflowX: 'auto' }}>
                <h3 style={{ textAlign: 'center', marginBottom: '15px' }}>Orders</h3>
                <Table style={{ marginTop: '5%' }}>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={`order-${order._id}`}>
                                <td style={{ borderBottom: 'none' }}>
                                    <img src={order.store.banner_image.url} height={50} width={50} style={{ marginRight: '10px' }} />
                                </td>
                                <td style={{ borderBottom: 'none', borderRight: 'none' }}>
                                    <Link to={`/order/${order.number}`} style={{color: 'black'}}>
                                        <p className='title-p'>Order #{order.number}</p>
                                    </Link>
                                    <p>Number of products: {order.products.length}</p>
                                </td>
                                <td style={{ borderBottom: 'none', borderLeft: 'none', borderRight: 'none' }}>
                                    <p className='title-p'>Status:</p>
                                    <p>{order.status}</p>
                                </td>
                                <td style={{ borderBottom: 'none', borderLeft: 'none' }}>
                                    <p className='title-p'>Date placed:</p>
                                    <p>{order.date.split("T")[0]}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}