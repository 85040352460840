import '../css/StoreCard.css'
import * as React from 'react';
import { addToFavorite } from "../utils/addToFavorite";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';

export default function StoreCard({store}) {

  const handleAddToFavorite = async () => {
    const response = await addToFavorite(store.id);
    if (response.ok) {
        // Success: Do something
    } else if (response.status === 401) {
        
    }
    else if (response.status === 403) {
        alert('Store already in favorites')
    }
};

  return (
    <Card id="store-card" sx={{ maxWidth: 345 }}>
        <Link key="cart-link" to={`/store/${store.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
            <CardMedia
                component="img"
                height="150"
                image={store.banner_image.url}
                title={store.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {store.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {store.description}
                </Typography>
            </CardContent>
        </Link>
        <CardActions>
            <IconButton aria-label="add to favorites" onClick={handleAddToFavorite}>
                <FavoriteIcon style={{color: 'black'}}/>
            </IconButton>
            <IconButton aria-label="share">
                <ShareIcon style={{color: 'black'}}/>
            </IconButton>
        </CardActions>
    </Card>
  );
}