import '../css/Stores.css'
import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import CircularIndeterminate from '../components/CircularLoad';
import mcbanner from '../assets/mcdobanner.PNG';
import Button from '@mui/material/Button';
import SimpleAlert from '../components/SimpleAlert';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Link } from 'react-router-dom';

export default function Store() {
    const [stores, setStores] = useState(null);

    useEffect(() => {
        fetchStores();
    }, []);
    
    const fetchStores = async () => {
        try {
          const response = await fetch('/api/user/stores');
          if (response.ok) {
            const data = await response.json();
            setStores(data.stores);
          } else {
            console.error('Failed to fetch stores');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    if (stores === null) {
        return (
            <div id="table">
                <Table bordered>
                    <CircularIndeterminate display={true}/>
                </Table>
            </div>
        )
    }

    if (stores.length === 0) {
        return (
            <h1 style={{textAlign: 'center'}}>No stores currently</h1>
        )
    }

    return (
        <div id='content'>
            {/* <SimpleAlert key="message-alert" message={message} type={type} display={alertDisplay}/> */}
            <div id="table" style={{ overflowX: 'auto' }}>
                <Table bordered>
                    <thead>
                        <tr style={{borderBottom: 'none'}}>
                            <th className='cart-th' style={{ borderRight: 'none', fontWeight: 'normal', fontSize: '23px' }}>Stores</th>
                            <th style={{ borderLeft: 'none', borderRight: 'none' }}></th>
                            <th style={{ borderLeft: 'none', }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stores.map((store) => (
                            <tr style={{borderTop: 'none'}} id={`store-${store._id}`} key={`store-${store._id}`}>
                                <td style={{borderRight: 'none'}}>
                                    <img className='storeImg' src={mcbanner} style={{marginRight: '10px'}}/>
                                </td>
                                <td className='storeName' style={{ borderRight: 'none', borderLeft: 'none' }}>
                                    <Link to={`/store/${store._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                        <p>{store.name}</p>
                                    </Link>
                                </td>
                                <td style={{ display: 'flex', alignItems: 'center', borderLeft: 'none' }}>
                                    <p>{store.description}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}