import '../css/StoreTypeResults.css'
import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { addToFavorite } from "../utils/addToFavorite";
import CircularIndeterminate from '../components/CircularLoad';
import StoreCard from '../components/StoreCard';

export default function StoreTypeResults() {
    const { type } = useParams();
    const [data, setData] = useState([]);
    const [totalStores, setTotalStores] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showMoreDisplay, setShowMoreDisplay] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchStoresByType();
    }, [type]);

    const handleShowMore = () => {
        setShowMoreDisplay(true)
        fetchStoresByType();
    };

    const fetchStoresByType = async () => {
        try {
            const response = await fetch(`/api/store/type/${type}?totalStores=${totalStores}`);
            if (response.ok) {
                const stores = await response.json();
                setLoading(false);
                setTotalStores(prevTotalStores => prevTotalStores + stores.length);
                setData(prevData => [...prevData, ...stores]);
                setShowMoreDisplay(false)
            } else {
                console.error('Failed to fetch stores');
                setError('Failed to fetch stores.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch stores.');
        }
    };

    const handleAddToFavorite = async (storeID) => {
        const response = await addToFavorite(storeID);
        if (response.ok) {
            // Success: Do something
        } else if (response.status === 401) {
            
        }
        else if (response.status === 403) {
            alert('Store already in favorites')
        }
    };

    if (data.length === 0) {
        return (
            <section id='storetype-container'>
                <CircularIndeterminate display={loading}/>
            </section>
        );
    }

    return (
        <section id='storetype-container'>
            {data.map(store => (
                <StoreCard key={store._id} store={store}/>
            ))}
        </section>
    );
}