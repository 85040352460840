import * as React from 'react';
import '../css/Store.css'
import mcwings from '../assets/mcwings.png';
import mcbanner from '../assets/mcdobanner.PNG';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { addToCart } from "../utils/addToCart";
import { removeFromCart } from "../utils/removeFromCart";
import { useEffect, useState } from "react";
import ListIcon from '@mui/icons-material/List';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CircularIndeterminate from '../components/CircularLoad';
import EditIcon from '@mui/icons-material/Edit';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Link } from 'react-router-dom';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Store() {
    const [user, setUser] = useState(null);
    const [store, setStore] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [showRatings, setShowRatings] = useState(false);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [category, setCategory] = useState('');
    const [clickedCategory, setClickedCategory] = useState(null);
    const { id } = useParams();
    // DIALOG
    const [open, setOpen] = useState(false);
    const [dialogProductId, setDialogProductId] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogImg, setDialogImg] = useState('');
    const [dialogDescription, setDialogDescription] = useState('');

    useEffect(() => {
        fetchUserStatus();
        fetchStore();
    }, []);

    const handleClickOpen = (productId, productName, productImg, productDescription) => {
        setDialogProductId(productId)
        setDialogTitle(productName);
        setDialogImg(productImg)
        setDialogDescription(productDescription)
        setOpen(true);
    };
    
    const handleClose = () => {
        setValue(0);
        setOpen(false);
    };

    const handleCategoryClick = (categoryName) => {
        setClickedCategory(categoryName);
    };

    const handleSave = async() => {
        if(value!==0){
            try {
                const response = await fetch(`/api/product/add-review/${dialogProductId}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        body: 'Test',
                        rating: value
                    })
                });
                if (response.ok) {
                    setOpen(false);
                } else {
                    console.error("Failed");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const handleCategoryValue = (event) => {
        setCategory(event.target.value);
    };

    const handleAddCategory = async() => {
        if(category !== '') {
            try {
                const response = await fetch(`/api/store/add-category/${id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name: category,
                        quantity: 0
                    })
                });
                if (response.ok) {
                    setCategory('');

                } else {
                    console.error("Failed");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }

    const handleAddToCart = async (productID) => {
        const response = await addToCart(id, productID, 1);
        if (response.ok) {
            // Success: Do something
        } else if (response.status === 401) {
            // do something
        }
    };

    const handleRemoveFromCart = (productID) => {
        removeFromCart(productID, () => {
          // do something
        });
    };

    const fetchUserStatus = async () => {
        try {
          const response = await fetch('/api/user/status');
          if (response.ok) {
            const results = await response.json();
            setUser(results.user)
          } else {
            console.error('Failed to fetch user');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const fetchStore = async() => {
        try {
            const response = await fetch(`/api/store/${id}`);
            if (response.ok) {
              const data = await response.json();
              setStore(data.store)
              setLoading(false);
            } else {
              console.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchRatings = async() => {
        try {
          const response = await fetch('/api/store/getRatings');
          if (response.ok) {
            const ratings = await response.json();
            setRatings(ratings)
          } else {
            console.error('Failed to fetch ratings');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const toggleMenu = () => {
        setShowRatings(false);
    }

    const toggleRatings = () => {
        //fetchRatings();
        setShowRatings(true);
    }

    const renderAddToCartButton = (product) => {
        if(user) {
            const productID = product._id.toString();
            const cartItem = user.cart.find(item => item.product.toString() === productID);
        
            // Render the button only if the product is not in the cart
            if (!cartItem) {
                return (
                    <Button 
                        variant="outlined" 
                        onClick={() => handleAddToCart(product.id)} 
                        style={{fontSize: '12px', padding: '0', display: (user && user.type === 'personal') ? 'block' : 'none', marginLeft: '10px', borderColor: 'black'}}
                    >
                        <AddShoppingCartIcon sx={{color: "black"}}/>
                    </Button>
                );
            } else {
                return (
                    <Button 
                        variant="outlined" 
                        onClick={() => handleRemoveFromCart(product.id)} 
                        style={{fontSize: '12px', padding: '0', display: (user && user.type === 'personal') ? 'block' : 'none', marginLeft: '10px', borderColor: 'black'}}
                    >
                        <RemoveShoppingCartIcon sx={{color: "black"}}/>
                    </Button>
                )
            }
        }
    };

    if(store === null || user === null) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    return (
        <section id="store-container">
            <div id='store-info'>
                <div id='banner_div'>
                    <img id='store-image-banner' src={store.banner_image.url}></img>
                </div>
                <h1 id='store-name' style={{marginTop: '1.5%'}}>{store.name}</h1>
                <p id='store-description' style={{fontWeight: 'lighter', marginBottom: '0'}}>{store.description}</p>
                <p id='store-location' style={{fontWeight: 'lighter'}}>{store.address.building}, {store.address.street}, {store.address.city}</p>
            </div>
            <div id='store-'>
                <div id='store-sections'>
                    <button onClick={toggleMenu} style={{backgroundColor: 'white', borderRadius: '10px', border: '1px solid rgb(188, 187, 187)', marginRight: '10px'}}><ListIcon/> Menu</button>
                    <button onClick={toggleRatings} style={{backgroundColor: 'white', borderRadius: '10px', border: '1px solid rgb(188, 187, 187)', marginRight: '10px'}}><StarOutlineIcon/> Ratings</button>
                    <Link to={`/store/${store.id}/add-product`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <button onClick={toggleRatings} style={{display: (user && user.type == 'business' && user.stores.includes(id)) ? 'inline' : 'none', backgroundColor: 'white', borderRadius: '10px', border: '1px solid rgb(188, 187, 187)', marginRight: '10px'}}><FastfoodIcon/> Add product</button>
                    </Link>
                    <Link to={`/store/${store.id}/edit`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <button onClick={toggleRatings} style={{display: (user && user.type == 'business' && user.stores.includes(id)) ? 'inline' : 'none', backgroundColor: 'white', borderRadius: '10px', border: '1px solid rgb(188, 187, 187)', marginRight: '10px'}}><EditIcon/> Edit store</button>
                    </Link>
                </div>
                <hr></hr>
                <div id='store-menu' style={{display: showRatings ? 'none' : 'flex'}}>
                    <div id='store-categories'>
                        {/* <p>The Great McDonald's Sale Menu Starts @99 (18)</p>
                        <p>The Great McDonald's Sale Menu Starts @99 (18)</p> */}
                        {store.categories.map(category => (
                            <a
                                key={category.name}
                                href={`#product-category-${category.name}`}
                                style={{
                                textDecoration: 'none',
                                color: 'black',
                                }}
                                onClick={() => handleCategoryClick(category.name)}
                            >
                                <p style={{ fontSize: '18px', fontWeight: '400', backgroundColor: clickedCategory === category.name ? 'lightgray' : 'transparent' }}>
                                    {category.name} ({category.products.length})
                                </p>
                          </a>
                        ))}
                        <form>
                            <input style={{width: '85%'}} required placeholder='Add category' onChange={handleCategoryValue} value={category}></input>
                            <button onClick={handleAddCategory} type='button'>+</button>
                        </form>
                    </div>
                    <div id='store-products'>
                        {store.categories.map(category => (
                            <React.Fragment key={category._id}>
                                <h4 id={`product-category-${category.name}`}>{category.name}</h4>
                                {category.products.map(product => (
                                    <div id='product' key={product._id}>
                                        <div id='product-image'>
                                            <img src={product.image.url} alt={product.name}></img>
                                        </div>
                                        <div id='product-details'>
                                            <h5>{product.name}</h5>
                                            <div id='product-rating'>
                                                <div id='rating-stars'>
                                                    <Rating name="read-only" style={{fontSize: '1rem'}} value={0} readOnly />
                                                    {/* <span style={{marginLeft: '5px'}}>{product.ratings.length} votes</span> */}
                                                </div>
                                                <div>
                                                    <Button 
                                                        variant="outlined" 
                                                        style={{fontSize: '14px', padding: '0', color: 'black', display: (user && user.type == 'personal') ? 'block' : 'none', borderColor: 'black'}} 
                                                        onClick={() => handleClickOpen(product._id, product.name, product.image.url, product.description)}
                                                    >
                                                        Rate
                                                    </Button>
                                                </div>
                                                <div>
                                                    {renderAddToCartButton(product)}
                                                </div>
                                            </div>
                                            <p style={{margin: '0', fontWeight: '400'}}>${product.price}</p>
                                            <p style={{fontSize: '0.9rem', fontWeight: 'lighter'}}>{product.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div id='store-ratings' style={{display: showRatings ? 'flex' : 'none'}}>
                    {/* {ratings === null ? 
                    <CircularIndeterminate display={true}/> : 
                    <h1>Ratings</h1>} */}
                    <h4>{store.name}'s Reviews</h4>
                </div>
            </div>
            <React.Fragment>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <img src={dialogImg} height={80} width={80}></img>
                        <DialogContentText style={{marginBottom: '10px'}} id="alert-dialog-slide-description">
                            {dialogDescription}
                        </DialogContentText>
                        <h3 style={{display: 'inline'}}>Leave a rate: </h3>
                        <Rating
                            name="simple-controlled"
                            value={value}
                            onChange={(event, newValue) => {
                            setValue(newValue);
                            }}
                        />
                        <p style={{marginTop: '10px', marginBottom: 0}}>Comments</p>
                        <input style={{width: '100%'}}></input>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </section>
    )
}