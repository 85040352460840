import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';
import { useForm } from "react-hook-form";

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="/">
          Delivery
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const defaultTheme = createTheme();

export default function Account(){
    const [results, setResults] = useState(null)
    const [loader, setLoader] = useState(false);
    const [alertDisplay, setAlertDisplay] = useState('none');
    const [type, setType] = useState('success');
    const [message, setMessage] = useState('');
    const [alertKey, setAlertKey] = useState(0);
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    
    const accountOptions = {
        first_name: { required: "First name cannot be blank" },
        last_name: { required: "Last name cannot be blank" },
        username: { required: "Username cannot be blank" },
        email: { required: "Email cannot be blank" }
    };

    useEffect(() => {
        fetchUserStatus();
    }, []);

    const fetchUserStatus = async () => {
        try {
          const response = await fetch('/api/user/status');
          if (response.ok) {
            const results = await response.json();
            setResults(results.user)
          } else {
            console.error('Failed to fetch user');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const handleAccountSubmit = async (formData) => {
        setLoader(true);
        const { first_name, last_name, username, email } = formData;
        try {
            const response = await fetch("/api/user/edit-account", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: { first_name, last_name, username, email }
                })
            });
            if (response.ok) {
                setMessage('Account updated');
                setType('success');
                setAlertKey(prevKey => prevKey + 1);
                setAlertDisplay('block');
            } else {
                console.error("Failed");
                setMessage('Error updating account');
                setType('error');
                setAlertKey(prevKey => prevKey + 1);
                setAlertDisplay('block');
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage('Error updating account');
            setType('error');
            setAlertKey(prevKey => prevKey + 1);
            setAlertDisplay('block');
        }
        setLoader(false);
    };
    const handleError = (errors) => {};

    const options = [{key: "Mr.", value: "Mr"}, {key: "Mrs.", value: "Mrs"}]

    if(results === null) {
       return (
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container key="main-container" component="main" maxWidth="xs">
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'black' }}>
                            <AccountCircleIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h1" variant="h5">
                            Account
                        </Typography>
                        <CircularIndeterminate display={true}/>
                    </Box>
                </Container>
            </ThemeProvider>
        )
    }

    return (
        <div>
            <SimpleAlert key={alertKey} message={message} type={type} display={alertDisplay}/>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container key="main-container" component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'black' }}>
                            <AccountCircleIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h1" variant="h5">
                            Account
                        </Typography>
                        <Box key="form-box" component="form" onSubmit={handleSubmit(handleAccountSubmit)} sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="first-name-field" item xs={12} sm={6}>
                                    <TextField
                                        key="first-name-input"
                                        autoComplete="first-name"
                                        name="first_name"
                                        required
                                        fullWidth
                                        id="first_name"
                                        label="First Name"
                                        autoFocus
                                        defaultValue={results.first_name}
                                        {...register("first_name", accountOptions.first_name)}
                                    />
                                    <small className="text-danger">
                                        {errors?.first_name && errors.first_name.message}
                                    </small>
                                </Grid>
                                <Grid key="last-name-field" item xs={12} sm={6}>
                                    <TextField
                                        key="last-name-input"
                                        required
                                        fullWidth
                                        id="last_name"
                                        label="Last Name"
                                        name="last_name"
                                        autoComplete="last-name"
                                        defaultValue={results.last_name}
                                        {...register("last_name", accountOptions.last_name)}
                                    />
                                    <small className="text-danger">
                                        {errors?.last_name && errors.last_name.message}
                                    </small>
                                </Grid>
                                <Grid key="username-field" item xs={12}>
                                    <TextField
                                        key="username-input"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        autoComplete="username"
                                        defaultValue={results.username}
                                        {...register("username", accountOptions.username)}
                                    />
                                    <small className="text-danger">
                                        {errors?.username && errors.username.message}
                                    </small>
                                </Grid>
                                <Grid key="email-field" item xs={12}>
                                    <TextField
                                        key="email-input"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        defaultValue={results.email}
                                        {...register("email", accountOptions.email)}
                                    />
                                    <small className="text-danger">
                                        {errors?.email && errors.email.message}
                                    </small>
                                </Grid>
                            </Grid>
                            <Button
                                key="sign-up-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'black' }}
                            >
                                Save
                            </Button>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                    <Copyright key="Pharmacy" sx={{ mt: 5 }} />
                </Container>
            </ThemeProvider>
        </div>
    )
}