import '../css/Home.css'
import { useState, useRef } from "react";
import food_background from '../assets/food_background.jpg';
import pizza from '../assets/pizza.jpg';
import chicken from '../assets/chicken.PNG';
import burger from '../assets/burger.jpg';
import pasta from '../assets/pasta.jpg';
import noodles from '../assets/noodles.jpg';
import tacos from '../assets/tacos.jpg';
import SessionAlert from '../components/SessionAlert';
import HomeItemList from '../components/HomeItemList';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function Home() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearchChange = async (event) => {
        const value = event.target.value;
        if(value.length === 0) {
            setSearchResults([])
        }
        const optionValue = document.getElementById('option2').value;
        setSearchTerm(value);
        if (value.length >= 2) {
        try {
            const response = await fetch(`/api/autocomplete?name=${value}&option=${optionValue}`);
            if (response.ok) {
            const data = await response.json();
            setSearchResults(data);
            } else {
            console.error('Failed to fetch autocomplete results');
            }
        } catch (error) {
            console.error('Error fetching autocomplete results:', error);
        }
        } else {
        setSearchResults([]);
        }
    };
    
    const changeSearchValue = (event) => {
        // const value = event.target.innerText;
        // const searchInput = document.getElementById('search-bar-mobile');
        // searchInput.value = value;
        // handleSubmitSearch();
        // setSearchResults([]);
    }

    const handleSubmitSearch = async () => {
        // const value = document.getElementById('search-bar-mobile').value;
        // const optionValue = document.getElementById('option2').value;
        // try {
        //   const response = await fetch(`/api/search?name=${value}&option=${optionValue}`);
        //   if (response.ok) {
        //     const data = await response.json();
        //     // send data as prop and redirect to search results page
        //     // navigate('/search-results', { state: { searchData: data, value } });
        //     // window.location.reload();
        //   } else {
        //     console.error('Failed to fetch results');
        //   }
        // } catch (error) {
        //   console.error('Error fetching results:', error);
        // }
    }

    const listRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const itemWidth = 150;
    const padding = 10;

    const scrollLeftHandler = () => {
        listRef.current.scrollLeft -= itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    const scrollRightHandler = () => {
        listRef.current.scrollLeft += itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };
    
    return (
        <section id='home-container'>
            <SessionAlert/>
            <div id='top-panel'>
                <img src={food_background} id='background-img'></img>
                <div id="search-heading-container">
                    <h1 class="search-heading">Delivery</h1>
                    <h2 class="search-heading">Discover the best food and drinks with the best restaurants</h2>
                </div>
                <div id='home-search-bar-box'>
                    <input
                        key="search-input"
                        className="form-control mr-sm-2"
                        id='home-search-bar'
                        type="search"
                        placeholder='Search for restaurants'
                        aria-label="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        required
                    />
                    <button class="btn btn-outline-success" id="home-search-btn" type="button" ><i class="fa fa-search"></i></button>
                    <ul id='ulsearch-list'>
                        {searchResults.map((result, index) => (
                            <a href={`/store/${result._id}`} style={{textDecoration: 'none'}}>
                                <li key={index} onClick={changeSearchValue} style={{color: 'black', fontWeight: '500', backgroundColor: 'white', padding: '5px'}}>
                                    <img src={result.banner_image.url} height='50px' width='50px' style={{marginRight: '10px'}}></img> {result.name}
                                </li>
                            </a>
                        ))}
                    </ul>
                </div>
            </div>
            <div id='bottom-panel'>
                <h3 id='bottompan-h3' style={{ fontWeight: '500', marginLeft: '16vw', paddingTop: '2vw' }}>What's on your mind?</h3>
                <div className="container-car">
                    <div className="carousel-view">
                        <button key="prev-btn" id="prev-btn" onClick={scrollLeftHandler}>
                            <ArrowLeftIcon style={{ fontSize: '25px' }}/>
                        </button>
                        <div key="item-list" id="item-list" className="item-list" ref={listRef} style={{ scrollLeft: scrollLeft }}>
                            <HomeItemList img={pizza} name={'Pizza'}/>
                            <HomeItemList img={chicken} name={'Chicken'}/>
                            <HomeItemList img={burger} name={'Burger'}/>
                            <HomeItemList img={pasta} name={'Pasta'}/>
                            <HomeItemList img={noodles} name={'Noodles'}/>
                            <HomeItemList img={tacos} name={'Tacos'}/>
                        </div>
                        <button key="next-btn" id="next-btn" onClick={scrollRightHandler}>
                            <ArrowRightIcon style={{ fontSize: '25px' }}/>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}