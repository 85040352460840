import * as React from 'react';
import { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleAlert from '../components/SimpleAlert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";

const defaultTheme = createTheme();

export default function EditAddress(){
    const { index } = useParams();
    const [address, setAddress] = useState(null);
    const [loader, setLoader] = useState(false);
    const [successAlert, setSuccessAlert] = useState('none');
    const [errorAlert, setErrorAlert] = useState('none');
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    
    const addressOptions = {
        street: { required: "Street cannot be blank" },
        state: { required: "State cannot be blank" },
        city: { required: "City cannot be blank" },
        building: { required: "Building cannot be blank" },
        floor: { required: "Floor cannot be blank" },
        phone_nb: { required: "Phone number cannot be blank" }
    };

    useEffect(() => {
        fetchAddress();
    }, []);

    const fetchAddress = async () => {
        setLoader(true)
        try {
            const response = await fetch('/api/user/status');
            if (response.ok) {
              const results = await response.json();
              setAddress(results.user.addresses[index])
            } else {
              console.error('Failed to fetch address');
            }
          } catch (error) {
            console.error('Error:', error);
          }
          setLoader(false)
    };

    const handleAddressUpdate = async (formData) => {
        setLoader(true);
        try {
            const { street, state, city, building, floor, extra_info, phone_nb } = formData;
            const response = await fetch(`/api/user/update-address/${index}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ address: { street, state, city, building, floor, extra_info, phone_nb } }),
            });
            if (response.status === 200) {
                setSuccessAlert('block')
            } else {
                setErrorAlert('block')
                console.error("Error");
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoader(false);
    };

    const handleDelete = async (event) => {
        event.preventDefault();
        setLoader(true);
        try {
            const response = await fetch(`/api/user/delete-address/${index}`, {
                method: "DELETE"
            });
            if (response.status === 200) {
                navigate('/user/addresses')
                window.location.reload();
            } else {
                setErrorAlert('block')
                console.error("Error");
            }
            setLoader(false);
        } catch (error) {
            console.error("Error:", error);
            setLoader(false);
        }
    };

    const handleError = (errors) => {};
    
    if(address === null) {
        return (
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
            <Container key="main-container" component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar key="avatar" sx={{ m: 1, bgcolor: "black" }}>
                        <HomeIcon />
                    </Avatar>
                    <Typography key="sign-in-title" component="h1" variant="h5">
                        Edit address
                    </Typography>
                    <Box key="form-box" component="form" noValidate sx={{ mt: 1 }}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
        )
    }

    return (
        <ThemeProvider key="theme-provider" theme={defaultTheme}>
            <SimpleAlert key="message1-alert" message="Address updated" type="success" display={successAlert}/>
            <SimpleAlert key="message2-alert" message="Error updating address" type="error" display={errorAlert}/>
            <Container key="main-container" component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar key="avatar" sx={{ m: 1, bgcolor: "black" }}>
                        <HomeIcon />
                    </Avatar>
                    <Typography key="sign-in-title" component="h1" variant="h5">
                        Edit address
                    </Typography>
                    <Box key="form-box" component="form" onSubmit={handleSubmit(handleAddressUpdate)} noValidate sx={{ mt: 1 }}>
                        <Grid key="grid-container" container spacing={2}>
                            <Grid key="cvv-field" item xs={12} sm={6}>
                                <TextField
                                    key="street-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="street"
                                    label="Street"
                                    id="street"
                                    defaultValue={address.street}
                                    {...register("street", addressOptions.street)}
                                />
                                <small className="text-danger">
                                    {errors?.street && errors.street.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="state-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="state"
                                    label="State"
                                    id="state"
                                    defaultValue={address.state}
                                    {...register("state", addressOptions.state)}
                                />
                                <small className="text-danger">
                                    {errors?.state && errors.state.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="city-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="city"
                                    label="City"
                                    id="city"
                                    defaultValue={address.city}
                                    {...register("city", addressOptions.city)}
                                />
                                <small className="text-danger">
                                    {errors?.city && errors.city.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="building-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="building"
                                    label="Building"
                                    id="building"
                                    defaultValue={address.building}
                                    {...register("building", addressOptions.building)}
                                />
                                <small className="text-danger">
                                    {errors?.building && errors.building.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="floor-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="floor"
                                    label="Floor"
                                    id="floor"
                                    defaultValue={address.floor}
                                    {...register("floor", addressOptions.floor)}
                                />
                                <small className="text-danger">
                                    {errors?.floor && errors.floor.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="phonenb-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="phone_nb"
                                    label="Phone number"
                                    id="phone_nb"
                                    defaultValue={address.phone_nb}
                                    {...register("phone_nb", addressOptions.phone_nb)}
                                />
                                <small className="text-danger">
                                    {errors?.phone_nb && errors.phone_nb.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12}>
                                <TextField
                                    key="extrainfo-input"
                                    margin="normal"
                                    fullWidth
                                    name="extra_info"
                                    label="Extra info"
                                    id="extra_info"
                                    defaultValue={address.extra_info}
                                    {...register("extra_info", addressOptions.extra_info)}
                                />
                                <small className="text-danger">
                                    {errors?.extra_info && errors.extra_info.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <Button
                                    key="sign-in-button"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 1, mb: 1, bgcolor: "black" }}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <Button
                                    key="sign-in-button"
                                    type="button"
                                    onClick={handleDelete}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 1, mb: 1, bgcolor: "red" }}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <div style={{marginTop: '30px'}}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </div>
                </Box>
            </Container>
        </ThemeProvider>
    )
}