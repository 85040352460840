import '../css/Test.css'
import mcwings from '../assets/mcwings.png';
import mcbanner from '../assets/mcdobanner.PNG';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import ListIcon from '@mui/icons-material/List';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CircularIndeterminate from '../components/CircularLoad';
import { useParams } from 'react-router-dom';

export default function Test() {
    const [store, setStore] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [showRatings, setShowRatings] = useState(false);
    const [value, setValue] = useState(2);
    const { name } = useParams();

    useEffect(() => {
        // fetchStore();
    }, []);

    const fetchStore = async() => {
        try {
            const response = await fetch(`/api/store/${name}`);
            if (response.ok) {
              const store = await response.json();
              setStore(store)
            } else {
              console.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchRatings = async() => {
        try {
          const response = await fetch('/api/store/getRatings');
          if (response.ok) {
            const ratings = await response.json();
            setRatings(ratings)
          } else {
            console.error('Failed to fetch ratings');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const toggleMenu = () => {
        setShowRatings(false);
    }

    const toggleRatings = () => {
        //fetchRatings();
        setShowRatings(true);
    }

    return (
        <section id="container">
            <div id='store-info'>
                <img id='product-image-banner' src={mcbanner}></img>
                <h1 id='store-name'>McDonald's</h1>
                <p id='store-description'>Burger, Wraps, Fast Food, Beverages</p>
                <p id='store-location'>Ansal Plaza Mall, Knowledge Park, Greater Noida</p>
            </div>
            <div id='store-'>
                <div id='store-sections'>
                    <button onClick={toggleMenu} style={{backgroundColor: 'white', borderRadius: '10px', border: '1px solid rgb(188, 187, 187)', marginRight: '10px'}}><ListIcon></ListIcon> Menu</button>
                    <button onClick={toggleRatings} style={{backgroundColor: 'white', borderRadius: '10px', border: '1px solid rgb(188, 187, 187)', marginRight: '10px'}}><StarOutlineIcon></StarOutlineIcon> Ratings</button>
                </div>
                <div id='store-menu' style={{display: showRatings ? 'none' : 'flex'}}>
                    <div id='store-categories'>
                        <p>The Great McDonald's Sale Menu Starts @99 (18)</p>
                        <p>The Great McDonald's Sale Menu Starts @99 (18)</p>
                    </div>
                    <div id='store-products'>
                        <h4 id='product-category-name'>The Great McDonald's Sale Menu Starts @99 (18)</h4>
                        <div id='product'>
                            <div id='product-image'>
                                <img src={mcwings}></img>
                            </div>
                            <div id='product-details'>
                                <h5>Chicken McWings 4pcs</h5>
                                <div id='product-rating'>
                                    <div id='rating-stars'>
                                        <Rating name="read-only" style={{fontSize: '1rem'}} value={4} readOnly />
                                    </div>
                                    <div id='votes-nb'>
                                        <span>86 votes</span>
                                    </div>
                                </div>
                                <p style={{margin: '0'}}>$ 10</p>
                                <p style={{fontSize: '0.9rem', fontWeight: 'lighter'}}>New Chicken McWings® in a lip-smacking Chilli and Lemon flavour.</p>
                            </div>
                        </div>

                        <div id='product'>
                            <div id='product-image'>
                                <img src={mcwings}></img>
                            </div>
                            <div id='product-details'>
                                <h5 >Chicken McWings 4pcs</h5>
                                <div id='product-rating'>
                                    <div id='rating-stars'>
                                        <Rating name="read-only" style={{fontSize: '1rem'}} value={4} readOnly />
                                    </div>
                                    <div id='votes-nb'>
                                        <span>86 votes</span>
                                    </div>
                                </div>
                                <p style={{margin: '0'}}>$ 10</p>
                                <p style={{fontSize: '0.9rem', fontWeight: 'lighter'}}>New Chicken McWings® in a lip-smacking Chilli and Lemon flavour.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='store-ratings' style={{display: showRatings ? 'flex' : 'none'}}>
                    {/* {ratings === null ? 
                    <CircularIndeterminate display={true}/> : 
                    <h1>Ratings</h1>} */}
                    <h4>McDonald's Reviews</h4>
                </div>
            </div>
        </section>
    )
}