export const removeFromCart= async (productID, callback) => {
    try {
        const response = await fetch('/api/user/removeFromCart', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ productID })
        });
        if (response.ok) {
            callback();
            window.location.reload();
        } else {
            console.error("Error");
        }
    } catch (error) {
        console.error('Error:', error);
    }
};