import '../css/AddAddress.css'
import * as React from 'react';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import SimpleAlert from '../components/SimpleAlert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";

const defaultTheme = createTheme();

export default function AddAddress(){
    const [loader, setLoader] = useState(false);
    const [successAlert, setSuccessAlert] = useState('none')
    const [errorAlert, setErrorAlert] = useState('none')

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        street: { required: "Street cannot be blank" },
        state: { required: "State cannot be blank" },
        city: {required: "City cannot be blank"},
        building: {required: "Building cannot be blank"},
        floor: {required: "Floor cannot be blank"},
        phone_nb: {required: "Phone number cannot be blank"}
    };

    const handleAddAddress = async (formData) => {
        setLoader(true);
        try {
            const { street, state, city, building, floor, extra_info, phone_nb } = formData;
            const response = await fetch("/api/user/add-address", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ address: { street, state, city, building, floor, extra_info, phone_nb } }),
            });
            if (response.status === 200) {
                setSuccessAlert('block')
            } else {
                setErrorAlert('block')
                console.error("Error");
            }
            setLoader(false);
        } catch (error) {
            console.error("Error:", error);
            setLoader(false);
        }
    };
    const handleError = (errors) => {};

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
              Delivery
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }    

    return (
        <ThemeProvider key="theme-provider" theme={defaultTheme}>
            <SimpleAlert key="message1-alert" message="Address added" type="success" display={successAlert}/>
            <SimpleAlert key="message2-alert" message="Error adding address" type="error" display={errorAlert}/>
            <Container key="main-container" component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    key="box"
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Avatar key="avatar" sx={{ m: 1, bgcolor: "black" }}>
                        <HomeIcon />
                    </Avatar>
                    <Typography key="sign-in-title" component="h1" variant="h5">
                        Add address
                    </Typography>
                    <Box key="form-box" component="form" onSubmit={handleSubmit(handleAddAddress)} noValidate sx={{ mt: 1 }}>
                        <Grid key="grid-container" container spacing={2}>
                            <Grid key="cvv-field" item xs={12} sm={6}>
                                <TextField
                                    key="street-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="street"
                                    label="Street"
                                    id="street"
                                    {...register("street", registerOptions.street)}
                                />
                                <small className="text-danger">
                                    {errors?.street && errors.street.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="state-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="state"
                                    label="State"
                                    id="state"
                                    {...register("state", registerOptions.state)}
                                />
                                <small className="text-danger">
                                    {errors?.state && errors.state.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="city-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="city"
                                    label="City"
                                    id="city"
                                    {...register("city", registerOptions.city)}
                                />
                                <small className="text-danger">
                                    {errors?.city && errors.city.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="building-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="building"
                                    label="Building"
                                    id="building"
                                    {...register("building", registerOptions.building)}
                                />
                                <small className="text-danger">
                                    {errors?.building && errors.building.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="floor-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="floor"
                                    label="Floor"
                                    id="floor"
                                    {...register("floor", registerOptions.floor)}
                                />
                                <small className="text-danger">
                                    {errors?.floor && errors.floor.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12} sm={6}>
                                <TextField
                                    key="phonenb-input"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="phone_nb"
                                    label="Phone number"
                                    id="phone_nb"
                                    {...register("phone_nb", registerOptions.phone_nb)}
                                />
                                <small className="text-danger">
                                    {errors?.phone_nb && errors.phone_nb.message}
                                </small>
                            </Grid>
                            <Grid key="expdate-field" item xs={12}>
                                <TextField
                                    key="extrainfo-input"
                                    margin="normal"
                                    fullWidth
                                    name="extra_info"
                                    label="Extra info"
                                    id="extra_info"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            key="sign-in-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: "black" }}
                        >
                            Save
                        </Button>
                    </Box>
                    <div style={{marginTop: '30px'}}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </div>
                </Box>
                <Copyright key="Delivery" sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    )
}