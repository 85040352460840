import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function SimpleAlert({ message, type, display = "none", close = true }) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
      setOpen(false);
      sessionStorage.removeItem('sessionMessage');
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={2} style={{ display: open ? display : 'none' }}>
      <Alert severity={type} onClose={close ? handleClose : null}>
        {message}
      </Alert>
    </Stack>
  );
}