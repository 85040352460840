import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressForm({formData, updateFormData}) {

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Update the form data in the parent component
    updateFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSaveAddressChange = (event) => {
    const { checked } = event.target;
    updateFormData((prevFormData) => ({
      ...prevFormData,
      saveAddress: checked ? 'yes' : 'no',
    }));
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="first_name"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onChange={handleInputChange}
            value={formData.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="last_name"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            onChange={handleInputChange}
            value={formData.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="street"
            name="street"
            label="Street"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
            value={formData.street}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
            onChange={handleInputChange}
            value={formData.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
            value={formData.state}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="building"
            name="building"
            label="Building"
            fullWidth
            autoComplete="building"
            variant="standard"
            onChange={handleInputChange}
            value={formData.building}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="floor"
            name="floor"
            label="Floor"
            fullWidth
            autoComplete="floor"
            variant="standard"
            onChange={handleInputChange}
            value={formData.floor}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phoneNb"
            name="phone_nb"
            label="Phone number"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
            value={formData.phone_nb}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="extraInfo"
            name="extra_info"
            label="Extra info"
            fullWidth
            autoComplete="extra-info"
            variant="standard"
            onChange={handleInputChange}
            value={formData.extra_info}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
              control={<Checkbox color="secondary" name="saveAddress" />}
              label="Use this address for payment details"
              checked={formData.saveAddress === 'yes'}
              onChange={handleSaveAddressChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}