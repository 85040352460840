import '../css/Navbar.css'
import * as React from 'react';
import { useEffect } from "react";
import { logout } from "../utils/logout";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuItem from '@mui/material/MenuItem';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import locationIcon from '../assets/location.jpg';
import StoreIcon from '@mui/icons-material/Store';

export default function Navbar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [language, setLanguage] = React.useState('english');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const navigate = useNavigate();

  const pages = [(authenticated && user.admin) ? 'Admin' : ''];
  const arabicToEnglish = {
      'الحساب': 'account',
      'العناوين': 'addresses',
      'الطلبات المسبقة': 'order-history',
      'تسجيل الدخول': 'login',
      'اشتراك': 'signup'
  };
  const arabicPages = ['الحساب', 'العناوين', 'الطلبات المسبقة'];
  const englishPages = [ 'Account', 'Addresses', 'Order history'];
  const englishLoginPages = ['Login', 'Signup'];
  const arabicLoginPages = ['تسجيل الدخول', 'اشتراك'];
  const settings = authenticated ? (language == 'english' ? englishPages : arabicPages) : (language == 'english' ? englishLoginPages : arabicLoginPages);

  const handleLogout = () => {
    logout();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSearchChange = async (event) => {
      const value = event.target.value;
      if(value.length === 0) {
          setSearchResults([])
      }
      const optionValue = document.getElementById('option2').value;
      setSearchTerm(value);
      if (value.length >= 2) {
      try {
          const response = await fetch(`/api/autocomplete?name=${value}&option=${optionValue}`);
          if (response.ok) {
          const data = await response.json();
          setSearchResults(data);
          } else {
          console.error('Failed to fetch autocomplete results');
          }
      } catch (error) {
          console.error('Error fetching autocomplete results:', error);
      }
      } else {
        setSearchResults([]);
      }
  };

  const changeSearchValue = (event) => {
    const value = event.target.innerText;
    const searchInput = document.getElementById('search-bar-mobile');
    searchInput.value = value;
    handleSubmitSearch();
    //setSearchResults([]);
  }

  const handleSubmitSearch = async () => {
      const value = document.getElementById('search-bar-mobile').value;
      const optionValue = document.getElementById('option2').value;
      try {
        const response = await fetch(`/api/search?name=${value}&option=${optionValue}`);
        if (response.ok) {
          const data = await response.json();
          // send data as prop and redirect to search results page
          navigate('/search-results', { state: { searchData: data, value } });
          window.location.reload();
        } else {
          console.error('Failed to fetch results');
        }
      } catch (error) {
        console.error('Error fetching results:', error);
      }
  }

  function handleAddressChange(event) {
    var selectedAddressIndex = event.target.value;
    var addressIndex = { addressIndex: selectedAddressIndex };

    fetch('/api/set-address', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(addressIndex)
    })
    .then(function(response) {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    })
    .catch(function(error) {
        console.error('Error changing address:', error);
    });
  }

  useEffect(() => {
      if (Cookies.get('language')) {
        setLanguage(Cookies.get('language'));
      } else {
        Cookies.set('language', 'english');
        setLanguage('english');
      }
      fetchUserStatus();
  }, []);

  const fetchUserStatus = async () => {
    try {
      const response = await fetch('/api/user/status');
      if (response.ok) {
        const results = await response.json();
        setAuthenticated(results.authenticated)
        setUser(results.user)
      } else {
        console.error('Failed to fetch user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const changeLanguage = () => {
      Cookies.set('language', language === 'english' ? 'arabic' : 'english');
      setLanguage(language === 'english' ? 'arabic' : 'english');
      window.location.reload();
  }

  return (
    <nav key="nav">
      <Box key="nav-box" sx={{ flexGrow: 1 }}>
        <AppBar key="app-bar" position="static" sx={{backgroundColor: "white", borderBottom: '1px solid #f0ebeb', boxShadow: '0', padding: '10px'}}>
          <Toolbar id="nav-toolbar" style={{ width: '73.5%', margin: '0 auto' }}>
              <KebabDiningIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: 'black' }} />
              {/* LEFT SIDE */}
              <Typography
                key="typography1"
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  // letterSpacing: '.3rem',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                Delivery
              </Typography>

              {/* MOBILE NAV DELIVERY h5 */}
              <KebabDiningIcon sx={{ display: { xs: 'flex', md: 'none', color: 'black' }, mr: 1 }} />
              <Typography
                key="typography2"
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                Delivery
              </Typography>
              <Box key="pages-box" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Link to="/addstore" style={{textDecoration: 'none'}}>
                  <Button  sx={{ fontSize: 'inherit', textTransform: 'none', textDecoration: 'none', color: 'black', display: (user && user.type == 'business') ? 'block' : 'none' }}>Add store</Button>
                </Link>
                <Link to="/user/admin" style={{display: (authenticated && user.admin) ? 'block' : 'none'}}>
                  <Button  sx={{ fontSize: 'inherit', textTransform: 'none', color: 'white', display: 'block' }}>Admin</Button>
                </Link>
              </Box>

              {/* SEARCH BAR */}
              <div id='search-bar-box'>
                  {/* <select key="select" id="option" name="option">
                      <option key="option" selected>
                        All
                      </option>
                      <option key="option1">Burgers</option>
                  </select> */}
                  <input
                    key="search-input"
                    className="form-control mr-sm-2"
                    id='search-bar'
                    type="search"
                    placeholder={'Search..'}
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    required
                  />
                  <button class="btn btn-outline-success" id="search-btn" type="button" onClick={handleSubmitSearch}><i class="fa fa-search"></i></button>
                  <ul id='ulsearch-list'>
                        {searchResults.map((result, index) => (
                            <Link to={`/store/${result._id}`} style={{textDecoration: 'none'}}>
                                <li key={index} onClick={changeSearchValue} style={{color: 'black', fontWeight: '500', backgroundColor: 'white', padding: '5px'}}>
                                    <img src={result.banner_image.url} height='50px' width='50px' style={{marginRight: '10px'}}></img> {result.name}
                                </li>
                            </Link>
                        ))}
                    </ul>
              </div>

              {/* RIGHT SIDE */}
              <Box key="right-side-box" sx={{ flexGrow: 1 }} />
                {/* ICONS  */}
                  {/* WISHLIST */}
                  <Link key="favoritestores-link" to="/user/favorite-stores" style={{ color: 'inherit', textDecoration: 'none', display: user && user.type !== 'business' ? 'block' : 'none' }}>
                    <IconButton key="fav-icon" id="fav-icon" size="large" style={{color: 'black'}}>
                      <Badge badgeContent={authenticated ? user.favorite_stores.length : 0} color="error">
                        <FavoriteIcon />
                      </Badge>
                    </IconButton>
                  </Link>

                  {/* SHOPPING CART */}
                  <Link key="cart-link" to="/user/cart" style={{ color: 'inherit', textDecoration: 'none', display: user && user.type !== 'business' ? 'block' : 'none' }}>
                    <IconButton key="cart-icon" id="cart-icon" size="large" style={{color: 'black'}}>
                      <Badge badgeContent={authenticated ? user.cart.length : 0} color="error">
                        <ShoppingCartIcon />
                      </Badge>
                    </IconButton>
                  </Link>

                  {/* STORES */}
                  <Link key="cart-link" to="/user/stores" style={{ color: 'inherit', textDecoration: 'none', display: user && user.type == 'business' ? 'block' : 'none' }}>
                    <IconButton size="large" aria-label="show 4 items" style={{color: 'black'}}>
                      <Badge sx={{ m: 1 }}>
                        <StoreIcon />
                      </Badge>
                    </IconButton>
                  </Link>

                  {/* ACCOUNT */}
                  <Tooltip key="tooltip" title="Open settings">
                    <IconButton key="icon-button" onClick={handleOpenUserMenu} sx={{ p: 0, color: 'black' }}>
                      <Badge sx={{ m: 1 }}>
                        <AccountCircleIcon sx={{color: 'black'}}/>
                      </Badge>
                    </IconButton>
                  </Tooltip>

                {/* THE LINKS FOR THE ACCOUNT ICON */}
                <Menu
                  key="menu"
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <Link key={setting} to={language == 'english' ? (`/user/${setting.toLowerCase().replace(/\s+/g, '-')}`) : (`/user/${arabicToEnglish[setting]}`)} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <MenuItem key={setting} onClick={handleCloseUserMenu}>
                          <Typography key={setting} textAlign="center">{setting}</Typography>
                      </MenuItem>
                    </Link>
                  ))}
                  {authenticated ? (
                    <>
                      <Link key='user-card' to='/user/card' style={{ color: 'inherit', textDecoration: 'none' }}>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">Credit card</Typography>
                        </MenuItem>
                      </Link>
                      <div onClick={handleLogout}>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Typography key='logout-btn' textAlign="center">{language == 'english' ? 'Logout' : 'الخروج'}</Typography>
                        </MenuItem>
                      </div>
                    </>
                  ) :
                    <span></span>
                  }
                </Menu>
            </Toolbar>

        </AppBar>
        {/* DRAWER IN MOBILE SCREENS */}
        <Drawer
            key="drawer"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px', textAlign: 'center' },
            }}
          >
          <Typography key="drawer-typography" variant="h6" sx={{ color: 'black', my: 2 }}>
            Delivery
          </Typography>
          <Divider key="divider"/>
          {/* THE LINKS INSIDE THE DRAWER */}
            <List key="drawer-list">
              {pages.map((item) => (
                <Link to={`/user/${item}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItem key={item} disablePadding>
                    <ListItemButton key={item} sx={{ textAlign: 'center' }}>
                      <ListItemText primary={item} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
        </Drawer>
      </Box>

      <div key="search-box-mobile" id="search-box-mobile" style={{display: 'none'}}>
          <div key="search-div" className="input-group">
            <input
              key="search-input"
              className="form-control mr-sm-2"
              type="search"
              id="search-bar-mobile"
              placeholder="Search"
              aria-label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              required
            />
            <select key="select" id="option2" name="option">
              <option key="option" selected>
                All
              </option>
              <option key="option1">Pain killers</option>
            </select>
            <button
              key="search-button"
              className="btn btn-outline-success"
              type="button"
              onClick={handleSubmitSearch}
              style={{ color: 'white', backgroundColor: 'black' }}
            >
              <i key="search-icon" className="fa fa-search"></i>
            </button>
          </div>
          <ul key="specs" id="specs" style={{listStyleType: 'none', margin: '0', padding: '0', position: 'absolute', width: '95%', zIndex: '1'}}>
            {searchResults.map((result, index) => (
              <li key={index} onClick={changeSearchValue} style={{fontWeight: '500', backgroundColor: '#fafaf2', padding: '5px'}}>{result.english_name}</li>
            ))}
          </ul>
      </div>

      <div key="location-mobile" id="location-mobile" style={{marginTop: '5%'}}>
            <img key="location-img" src={locationIcon} width="24" height="24" />
            <p key="location-paragraph" style={{ display: 'inline', fontWeight: 100 }}>
                Delivery to
                <select
                    key="location-select"
                    className="addressSelect"
                    id="addressSelect2"
                    onChange={handleAddressChange}
                    name="addChosen"
                    style={{ color: '#20b2aa', border: '0px', marginLeft: '5px', color: 'black' }}
                >
                    {user && user.addresses.length === 0 ? (
                        <option key="no-address" selected>
                            No addresses
                        </option>
                    ) : (
                        <> 
                          <option key="address-selected" selected>
                              {user && user.favorite_address >-1 && user.addresses[user.favorite_address].street}
                          </option>
                          {user && user.addresses.map((address, index) => (
                              address._id !== user.addresses[user.favorite_address]._id && <option value={index}>{address.street}</option>
                          ))}
                        </>
                    )}
                </select>
            </p>
        </div>
        <div id='mobile-line' style={{ display: 'none', marginBottom: '25px' }}></div>
    </nav>
  );
}