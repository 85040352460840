import '../css/AddStore.css'
import { useNavigate  } from 'react-router-dom';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useForm } from "react-hook-form";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
         Delivery
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AddStore() {
    const [loader, setLoader] = useState(false);
    const [type, setType] = useState('');
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        name: { required: "First name cannot be blank" },
        description: { required: "Last name cannot be blank" },
        street: { required: "Street cannot be blank" },
        city: {required: "City cannot be blank"},
        building: {required: "Building cannot be blank"}
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleAddStore = async (formData) => {
        setLoader(true)
        const { name, description, street, city, building } = formData;
        const storeData = {
            store: {
                name,
                description,
                type,
                street, 
                city, 
                building
            }
        };

        const form = new FormData();
        form.append('image', formData.image[0]);
        Object.entries(storeData.store).forEach(([key, value]) => {
            form.append(`store[${key}]`, value);
        });

        try {
            const response = await fetch("/api/store/add", {
                method: "POST",
                body: form
            });
            if (response.ok) {
                // sessionStorage.setItem('sessionMessage', 'Welcome!');
                // navigate("/");
                // window.location.reload();
            } else {
                console.error("Failed");
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoader(false)
    };
    const handleError = (errors) => {};

    const options = [
        {key: "Pizza", value: "Pizza"},
        {key: "Chicken", value: "Chicken"},
        {key: "Burger", value: "Burger"},
        {key: "Pasta", value: "Pasta"},
        {key: "Noodles", value: "Noodles"},
        {key: "Tacos", value: "Tacos"}
    ]

    return (
        <div id='addstore-container'>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container key="main-container" component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'black' }}>
                            <AddBusinessIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h1" variant="h5">
                            Register store
                        </Typography>
                        <Box key="form-box" component="form" noValidate onSubmit={handleSubmit(handleAddStore, handleError)} sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="name-field" item xs={12} sm={6}>
                                    <TextField
                                        key="name-input"
                                        name="name"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        autoFocus
                                        {...register("name", registerOptions.name)}
                                    />
                                    <small className="text-danger">
                                        {errors?.name && errors.name.message}
                                    </small>
                                </Grid>
                                <Grid key="description-field" item xs={12} sm={6}>
                                    <TextField
                                        key="description-input"
                                        required
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        name="description"
                                        {...register("description", registerOptions.description)}
                                    />
                                    <small className="text-danger">
                                        {errors?.description && errors.description.message}
                                    </small>
                                </Grid>
                                <Grid key="title-field" item xs={12} sm={6}>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Type"
                                                value={type}
                                                onChange={handleTypeChange}
                                                >
                                                {options.map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>
                                                    {item.key}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <small className="text-danger">
                                        {errors?.username && errors.username.message}
                                    </small>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        id="image"
                                        name="image"
                                        inputProps={{ multiple: false }}
                                        {...register("image")}
                                    />
                                </Grid>
                                {/* <p>Address</p> */}
                                <Grid key="street-field" item xs={12}>
                                    <TextField
                                        key="street-input"
                                        name="street"
                                        required
                                        fullWidth
                                        id="street"
                                        label="Street"
                                        autoFocus
                                        {...register("street", registerOptions.street)}
                                    />
                                    <small className="text-danger">
                                        {errors?.street && errors.street.message}
                                    </small>
                                </Grid>
                                <Grid key="city-field" item xs={12}>
                                    <TextField
                                        key="city-input"
                                        required
                                        fullWidth
                                        id="city"
                                        label="City"
                                        name="city"
                                        {...register("city", registerOptions.city)}
                                    />
                                    <small className="text-danger">
                                        {errors?.city && errors.city.message}
                                    </small>
                                </Grid>
                                <Grid key="building-field" item xs={12}>
                                    <TextField
                                        key="building-input"
                                        required
                                        fullWidth
                                        id="building"
                                        label="Building"
                                        name="building"
                                        {...register("building", registerOptions.building)}
                                    />
                                    <small className="text-danger">
                                        {errors?.building && errors.building.message}
                                    </small>
                                </Grid>
                            </Grid>
                            <Button
                                key="sign-up-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'black' }}
                            >
                                Register
                            </Button>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );
}