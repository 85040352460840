export const addToFavorite= async (storeID, callback) => {
    try {
        const response = await fetch('/api/user/addToFavorite', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ storeID })
        });
        if (response.ok) {
            // callback();
            sessionStorage.setItem('sessionMessage', 'Store added to favorites');
            window.location.reload();
        } else if (response.status === 401){
            console.error("Error: You must be logged in");
        }
        else if (response.status === 403){
            console.error("Error: Store already in favorites");
        }
        return response;
    } catch (error) {
        console.error('Error:', error);
    }
};