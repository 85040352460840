import '../css/SignUp.css'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function SignUp() {
    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
               Delivery
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }

    return (
        <section id='signup-container'>
            <div id='signup-box'>
                <h2>Account Type</h2>
                <div id='signup-options' style={{marginTop: '20px'}}>
                    <Link href="/user/signup/personal">
                        <Button className='signup-option1' variant="outlined" style={{borderColor: 'black', color: 'black'}}>Personal account</Button>
                    </Link>
                    <Link href="/user/signup/business">
                        <Button className='signup-option2' variant="outlined" style={{borderColor: 'black', color: 'black'}}>Business account</Button>
                    </Link>
                </div>
            </div>
            <Copyright key="copyright" sx={{ mt: 5 }} />
        </section>
    )
}